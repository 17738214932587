import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { Subscription, filter, take } from 'rxjs';
import { SafeHtmlPipe } from '../../../../shared/pipes/safe-html/safe-html.pipe';
import { SafariUiAccordionReduxObject } from '../../../../state/actions/layout-actions';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'sl-ui-kit-safari-accordion',
  templateUrl: './safari-accordion.component.html',
  styleUrls: ['./safari-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CollapseModule, NgIf, SafeHtmlPipe]
})
export class SafariAccordionComponent implements OnInit, OnDestroy {
  @Input()
  keepStateOnDestroy = false;
  @Input()
  defaultExpanded = true;
  @Input()
  hasHeader = true;
  @HostBinding('attr.id')
  @Input()
  id: string;
  @Input()
  name: string;
  @Input()
  button1: boolean;
  @Input()
  button1Label: string;
  @Input()
  button1Class: string;
  @Input()
  button1Style: string;
  @Input()
  button1AdditionalText: string;
  @Input()
  button1AdditionalTextClass: string;
  @Input()
  button1AdditionalHtml: string;
  @Input()
  button1AdditionalHtmlClass: string;

  private _headerClasses = 's_sec';

  @Input()
  set headerClasses(value: string) {
    this._headerClasses = 's_sec';
    if (value) {
      this._headerClasses = [this._headerClasses, value].join(' ');
    }
  }

  get headerClasses(): string {
    return this._headerClasses;
  }

  @Input()
  wrapperClasses = '';

  // We could have just inherited from safaribasecomponent and use super.subscribe
  // but it seems unneccesary to import all that additional stuff just for this
  private _accordionSubscriptions: Subscription[] = [];
  @Output()
  button1ClickEvent = new EventEmitter<any>();

  get isExpanded() {
    return !this.isCollapsed;
  }
  isCollapsed = true;
  get wrapperId() {
    return this.id + '-wrapper';
  }
  get headerId() {
    return this.id + '-header';
  }
  get contentId() {
    return this.id + '-content';
  }
  constructor(
    private _reduxWrapperService: ReduxWrapperService,
    private _accordionReduxObject: SafariUiAccordionReduxObject
  ) {}

  ngOnDestroy(): void {
    for (const sub of this._accordionSubscriptions) {
      if (sub != null) {
        sub.unsubscribe();
      }
    }
    this._accordionSubscriptions = [];

    if (!this.keepStateOnDestroy) {
      this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.clearState({ payload: { id: this.id } }));
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method -- in dev
  ngOnInit(): void {
    let state = null;
    if (this.id == null) {
      this.id = uuidv4();
    }
    // Before anything let's see if there's any state here (before dispatch activate because that would alwasy creat
    // a new state)
    this._reduxWrapperService
      .getGenericSelector(this._accordionReduxObject.default.selectors.getState(this.id))
      .pipe(take(1))
      .subscribe(o => {
        state = o;
      });
    // Always dispatch active onInit - regardless of whether there was state here before or not
    this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.setActive({ payload: { id: this.id, active: true } }));
    // If there was nothing in the state initially then set it according to this accordion's input props
    // (Presence of state in OnInit would indicate either refresh from Save OR accordion controller's settings)
    if (state == null) {
      const expanded = this.defaultExpanded;
      if (expanded) {
        this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionExpanded({ payload: { id: this.id } }));
      } else {
        this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionCollapsed({ payload: { id: this.id } }));
      }
    }

    this._accordionSubscriptions.push(
      this._reduxWrapperService.getGenericSelector(this._accordionReduxObject.default.selectors.getIsExpanded(this.id)).subscribe(isExpanded => {
        this.isCollapsed = !isExpanded;
      })
    );

    this._accordionSubscriptions.push(
      this._reduxWrapperService
        .listenForAction(this._accordionReduxObject.default.actions.accordionCollapse)
        .pipe(filter(o => o.payload.id == this.id))
        .subscribe(() => {
          this.collapseAccordion();
        })
    );
    this._accordionSubscriptions.push(
      this._reduxWrapperService
        .listenForAction(this._accordionReduxObject.default.actions.accordionExpand)
        .pipe(filter(o => o.payload.id == this.id))
        .subscribe(() => {
          this.expandAccordion();
        })
    );
  }
  private _toggleAccordion() {
    this.isCollapsed = !this.isCollapsed;
    this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionToggled({ payload: { id: this.id } }));
    if (this.isExpanded) {
      this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionExpanded({ payload: { id: this.id } }));
    } else {
      this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionCollapsed({ payload: { id: this.id } }));
    }
  }

  toggle() {
    this._toggleAccordion();
  }
  expandAccordion() {
    this.isCollapsed = false;
    this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionExpanded({ payload: { id: this.id } }));
  }
  collapseAccordion() {
    this.isCollapsed = true;
    this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionCollapsed({ payload: { id: this.id } }));
  }

  onbutton1Click() {
    this.button1ClickEvent.emit();
  }
}
export class Accordion {
  id: string;
  isExpanded: boolean;
  isActive: boolean;
}
export class AccordionController {
  id: string;
  isExpanded: boolean;
}
