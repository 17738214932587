import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '@safarilaw-webapp/shared/auth';
import { LoggerService } from '@safarilaw-webapp/shared/logging';

@Component({
  selector: 'sl-root-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {
  constructor(private _auth: AuthService, private _route: ActivatedRoute, private _logger: LoggerService) {}

  ngOnInit() {
    const paramMap: ParamMap = this._route.snapshot.queryParamMap;
    const error = paramMap.get('error');
    const errorDescription = paramMap.get('error_description');

    if (error) {
      this._logger.LogLoginErrorPageViewToElmah({
        error,
        errorDescription
      });
    }

    /* TODO: If we get error="unauthorized" and errorDescription="user is blocked" (these messages indicate that the user has been deactivated), we should:
     * #3958 Handle inactive user login with a new static page */
    this._auth.handleAuthentication();
  }
}
