import { Injectable } from '@angular/core';

import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { SafariCompanySettingsModel } from '../../_comanage-api-models.generated';

import { CompanySettings } from '../models/app/company-settings';

@Injectable({ providedIn: 'root' })
export class CompanySettingsAdapter extends ApiDataAdapter<CompanySettings> {
  toUpdateModel(formModel: CompanySettings): SafariRequired<SafariCompanySettingsModel> {
    return {
      authenticationConnectionName: formModel.authenticationConnectionName,
      domainName: formModel.domainName,
      safariNumberPad: formModel.safariNumberPad,
      whiteLabelEmailDomain: formModel.whiteLabelEmailDomain,
      whiteLabelEmailType: formModel.whiteLabelEmailType,
      enableRetentionPolicies: formModel.enableRetentionPolicies,
      employeeSize: formModel.employeeSize,
      industryClassification: formModel.industryClassification,
      enableCompanyEditablePicklists: formModel.enableCompanyEditablePicklists,
      useServePort: formModel.useServePort,
      enableDataExfiltrationProtection: formModel.enableDataExfiltrationProtection,
      enableScim: formModel.enableScim,
      enableSubjectSearch: undefined,
      enableAccountLookup: undefined,
      garnishmentProcessing: undefined,
      scimSettings: formModel.scimSettings
        ? {
            enabled: formModel.scimSettings.enabled,
            sendNewUserWelcomeEmail: formModel.scimSettings.sendNewUserWelcomeEmail
          }
        : undefined
    };
  }

  fromGetModel(getModel: SafariCompanySettingsModel): SafariRequired<CompanySettings> {
    /* ID property cannot be missing or this class cannot be used with CrudService.
     * ID property cannot be provided, because we merge this over the Company object, which has a real ID value that must be preserved */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Hack to work around missing ID property.
    const appModel: SafariRequired<CompanySettings> = {
      authenticationConnectionName: null,
      domainName: null,
      safariNumberPad: null,
      whiteLabelEmailDomain: getModel.whiteLabelEmailDomain,
      whiteLabelEmailType: getModel.whiteLabelEmailType,
      enableRetentionPolicies: getModel.enableRetentionPolicies,
      id: '',
      employeeSize: getModel.employeeSize,
      industryClassification: getModel.industryClassification,
      enableCompanyEditablePicklists: getModel.enableCompanyEditablePicklists,
      useServePort: getModel.useServePort,
      enableDataExfiltrationProtection: getModel.enableDataExfiltrationProtection,
      enableScim: getModel.enableScim,
      garnishmentProcessing: getModel.garnishmentProcessing,
      scimSettings: getModel.scimSettings
        ? {
            enabled: getModel.scimSettings.enabled,
            sendNewUserWelcomeEmail: getModel.scimSettings.sendNewUserWelcomeEmail
          }
        : undefined
    };
    return appModel;
  }
}
