import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppUiReduxObject } from '@safarilaw-webapp/shared/app-bootstrap';
import { AuthService, UserVerificationService } from '@safarilaw-webapp/shared/auth';
import { SafariInjector } from '@safarilaw-webapp/shared/common-objects-models';
import { ErrorType } from '@safarilaw-webapp/shared/error-handling-message-parser';
@Component({
  selector: 'sl-root-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent extends SafariInjector implements OnInit {
  private _sanitizer: DomSanitizer;

  html: SafeHtml = null;
  constructor(
    protected _authService: AuthService,
    protected _store: Store<any>,
    protected _userVerificationService: UserVerificationService,
    protected _router: Router,
    protected _appUiReduxObject: AppUiReduxObject
  ) {
    super();
    this._sanitizer = this.inject(DomSanitizer);
  }

  logout() {
    this._store.dispatch(this._appUiReduxObject.default.actions.toggleBlockUi({ payload: { value: true } }));
    this._authService.logout();
  }

  protected checkForUser() {
    // if we got to this page because we have an auth0 user but no safari user
    // then try to re-run sso init

    this._userVerificationService.checkForUser().subscribe(isUserFound => {
      if (!isUserFound) {
        // 404 implies we need to do the SSO hookup OR the user might need to choose a company
        this._userVerificationService.checkForCompanies().subscribe(hasCompanies => {
          if (hasCompanies) {
            void this._router.navigate(['/select-company']);
          } else {
            void this._router.navigate(['/sso-init']);
          }
        });
      }
    });
  }

  ngOnInit() {
    // We'll wrap this in try/catch just in case anything here fails.
    // If it does it will simply result in a regular 403 page rendering
    try {
      const errorObject = window['safari-error-state']?.errorObject;
      const thrownError = errorObject?.originalError;

      if (errorObject.errorType == ErrorType.Firewall && thrownError) {
        this.html = this._sanitizer.bypassSecurityTrustHtml(thrownError.error);
      }
    } catch {}

    this.checkForUser();
  }
  onFrameLoaded(event) {
    // Just some UI code to make iframe fit the length of its content
    // It's a nice-to-have so we'll set up try/catch block and if it fails
    // no big deal
    try {
      const iframe = event.srcElement;
      if (iframe) {
        iframe.height = iframe.contentWindow?.document?.body?.scrollHeight || 500;
        iframe.height += 20;
      }
    } catch {}
  }
}
