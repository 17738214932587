import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthCallbackComponent } from './login-logout/components/auth-callback/auth-callback.component';
import { LoginComponent } from './login-logout/components/login/login.component';
import { LogoutComponent } from './login-logout/components/logout/logout.component';

import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { SharedAuthModule } from '@safarilaw-webapp/shared/auth';
import { PageNotFoundComponent } from './error/components/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './error/components/unauthorized/unauthorized.component';

import { PageErrorComponent } from './error/components/page-error/page-error.component';

import { SafariAccordionComponent } from '@safarilaw-webapp/shared/ui-kit/accordion';
import { SafariButtonComponent } from '@safarilaw-webapp/shared/ui-kit/button';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CoUserSelectCompanyComponent } from './co-user-select-company/components/co-user-select-company/co-user-select-company.component';
import { CoUserSsoInitComponent } from './co-user-sso-init/components/co-user-sso-init/co-user-sso-init.component';
import { GatewayTimeoutComponent } from './error/components/gateway-timeout/gateway-timeout.component';
import { LoginErrorComponent } from './error/components/login-error/login-error.component';
import { LoginNoUserComponent } from './error/components/login-no-user/login-no-user.component';
import { ClearStorageComponent } from './management/components/clear-storage/clear-storage.component';
import { InterstitialComponent } from './management/components/interstitial/interstitial.component';
import { RedirectorComponent } from './redirector/components/redirector.component';
import { TermsOfServiceComponent } from './terms-of-service/components/terms-of-service/terms-of-service.component';
@NgModule({
  imports: [SafariAccordionComponent, SafariButtonComponent, CommonModule, RouterModule, SharedAuthModule, FormsModule, StoreModule.forFeature('root', {}), CollapseModule.forRoot()],

  declarations: [
    RedirectorComponent,
    AuthCallbackComponent,
    LogoutComponent,
    LoginComponent,
    PageNotFoundComponent,
    GatewayTimeoutComponent,
    UnauthorizedComponent,
    PageErrorComponent,
    ClearStorageComponent,
    LoginErrorComponent,
    InterstitialComponent,
    LoginNoUserComponent,
    TermsOfServiceComponent,
    CoUserSsoInitComponent,
    CoUserSelectCompanyComponent
  ],
  exports: [
    RedirectorComponent,
    LoginErrorComponent,
    AuthCallbackComponent,
    LogoutComponent,
    LoginComponent,
    PageNotFoundComponent,
    GatewayTimeoutComponent,
    UnauthorizedComponent,
    PageErrorComponent,
    ClearStorageComponent,
    LoginNoUserComponent,
    CoUserSsoInitComponent,
    TermsOfServiceComponent
  ]
})
export class SharedCommonPagesModule {}
