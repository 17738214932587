import { Component, Input } from '@angular/core';
import { MaintenanceInfo } from '@safarilaw-webapp/shared/app-bootstrap';

@Component({
  selector: 'sl-rms-maintenance-splash',
  templateUrl: './maintenance-splash.html',
  styleUrls: ['./maintenance-splash.scss']
})
export class MaintenanceSplashComponent {
  @Input()
  maintenanceInfo: MaintenanceInfo = undefined;

  get title() {
    return this.maintenanceInfo?.splash?.title || 'TRAIL MAINTENANCE UNDERWAY';
  }
  get details() {
    return this.maintenanceInfo?.splash?.details || 'Safari is performing scheduled maintenance. Please check back later.';
  }
}
