import { InjectionToken } from '@angular/core';
import { SafariObjectId, SafariReduxFileTransferObjectDefinition } from '@safarilaw-webapp/shared/common-objects-models';

export class ReduxBoolean {
  value: boolean;
}
export class ReduxString {
  value: string;
}
export class ReduxAny {
  value: any;
}
export class ReduxObjectSaveInfo {
  id: SafariObjectId;
  saving: boolean;
}
export class ReduxFormErrorInfo {
  keyControl: string;
  keyError: string;
  error: any;
}

// eslint-disable-next-line no-bitwise -- intended

export const FILE_TRANSFER_OBJECT = new InjectionToken<SafariReduxFileTransferObjectDefinition>('FILE_TRANSFER_OBJECT');
export const FILE_TRANSFER_OBJECTS = new InjectionToken<SafariReduxFileTransferObjectDefinition[]>('FILE_TRANSFER_OBJECTS');
