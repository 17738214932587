import { Injectable } from '@angular/core';
import { ActionCreator, ActionsSubject, DefaultProjectorFn, MemoizedSelector, NotAllowedCheck, Store } from '@ngrx/store';
import { Action, TypedAction } from '@ngrx/store/src/models';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReduxWrapperService {
  constructor(private ___store: Store<any>, private _dispatcher: ActionsSubject) {}

  listenForAction<T extends string, P extends object>(action: ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & TypedAction<T>>): Observable<P & TypedAction<T>>;
  listenForAction<T extends string>(action: ActionCreator<T, () => TypedAction<T>>): Observable<TypedAction<T>>;
  listenForAction(action: Action) {
    return this._dispatcher.pipe(filter(o => o.type == action.type));
  }
  dispatchGenericAction(action: any, async = false): void {
    if (async) {
      setTimeout(() => this.___store.dispatch(action));
    } else {
      this.___store.dispatch(action);
    }
  }
  // I think filterNull is almost always the case but let's wait until we are in the beggining of an iteration before
  // we flip default to true
  getGenericSelector<T>(selector: MemoizedSelector<object, T, DefaultProjectorFn<T>>, filterNull = false): Observable<T> {
    if (filterNull) {
      return this.___store.select(selector).pipe(filter(o => o != null));
    }
    return this.___store.select(selector);
  }
}
