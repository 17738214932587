import { Component } from '@angular/core';
import { STORAGE_AUTH_TOKEN_EXPIRATION_KEY } from '@safarilaw-webapp/shared/auth';
import { InitBaseComponent } from '@safarilaw-webapp/shared/common-pages';

@Component({
  selector: 'sl-rms-init',
  templateUrl: '../../../../../libs/shared/common-pages/src/lib/init/init.component.html',
  styleUrls: ['../../../../../libs/shared/common-pages/src/lib/init/init.component.scss']
})
export class InitComponent extends InitBaseComponent {
  override checkAuth(): boolean {
    const lastTokenExpiration = localStorage.getItem(STORAGE_AUTH_TOKEN_EXPIRATION_KEY);
    if (window.location.pathname.toLowerCase() == '/auth-callback') {
      // We're already handling a token callback. We don't need to send them out to login again.
      return true;
    }
    // If they're not authenticated and their Auth0 session is likely to still be alive, just send them through to the login page.
    //
    // Note that as of 2024-03-09, we configure the Auth0 session inactivity timeout as 3 hours, and token expiration as 1 hour.
    // While we could go right up to the 3 hour mark, I think it's a fair balance to lower this automatic login to the token lifespan,
    // which will renew every hour and reset the inactivity timeout.
    //
    // This tracking is across tabs, so it's more accurate than only tracking the expiration of a token within a tab. Whenever a new token
    // is issued in any tab (that is, they touch Auth0), we'll update the expiration time in local storage.
    //
    // It is possible that a user who is continuously active for 20 hours will hit the required login limit. I think this is fine because
    // the user account has already been created and they won't get stuck on the Auth0 login page.

    if (!this._authService.isAuthenticated() && lastTokenExpiration && new Date(+lastTokenExpiration) > new Date()) {
      this._authService.login(window.location.pathname);
      return false;
    }
    return true;
  }
}
