import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, mergeMap, of, take } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { UserVerificationService } from '../../services/user/user-verification.service';
import { AuthReduxObject } from '@safarilaw-webapp/shared/common-objects-models';

@Injectable({
  providedIn: 'root'
})
export class AuthEffect {
  constructor(private _actions: Actions<any>, private _authRO: AuthReduxObject, private _authService: AuthService, private _simpleUserService: UserVerificationService) {}

  // The effect below is a bit non-traditional in that they don't return an action
  // and NGRX framework is not expecting them to (dispatch: false)
  // Instead, Success/Fail actions for these messages are issued by the auth service itself
  // (Too scary to be refactoring that out so this effect is just a thin proxy)
  loginEffect$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(this._authRO.default.actions.login),
        mergeMap(o => {
          const payload = o.payload;
          if (payload) {
            this._authService.login(payload.redirectTargetRoute, payload.prefilledEmail, payload.connection, payload.startTimer);
          } else {
            this._authService.login();
          }
          return of(null);
        })
      ),
    {
      dispatch: false
    }
  );

  // Similar to login effect this one doesn't return neither success or fail action.
  logoutEffect$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(this._authRO.default.actions.logout),
        mergeMap(o => {
          this._authService.logout();
          return of(null);
        })
      ),
    {
      dispatch: false
    }
  );

  refreshCompaniesEffect$ = createEffect(() =>
    this._actions.pipe(
      ofType(this._authRO.default.actions.refreshCompanies),
      mergeMap(() =>
        this._simpleUserService.getCompanies().pipe(
          map(companies => this._authRO.default.actions.refreshCompaniesSuccess({ payload: { companies } })),
          catchError(() => of(this._authRO.default.actions.refreshCompaniesFailed({ payload: {} })))
        )
      )
    )
  );

  ensurePasswordlessUserExistsEffect$ = createEffect(() =>
    this._actions.pipe(
      ofType(this._authRO.default.actions.ensurePasswordlessUserExists),
      mergeMap(action =>
        this._simpleUserService.checkForUser(action.payload.email).pipe(
          map(() => this._authRO.default.actions.ensurePasswordlessUserExistsSuccess({ payload: action.payload })),
          catchError((theError: Error | string) => of(this._authRO.default.actions.ensurePasswordlessUserExistsFailed({ payload: { ...action.payload, error: theError } })))
        )
      )
    )
  );

  requestNewTokenForErrorHandlerEffect$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(this._authRO.default.actions.refreshTokenForErrorHandler),
        mergeMap(() => {
          this._authService.requestNewTokenForErrorHandler$().pipe(take(1)).subscribe();
          return of(null);
        })
      ),
    {
      dispatch: false
    }
  );
}
