import { Injectable } from '@angular/core';
import { AppConfigurationService } from '../appconfiguration/appconfiguration.service';

export enum FeatureFlag {
  DemoPreviews = 'demoPreviews',
  DevTools = 'devTools',
  DevExceptions = 'devExceptions'
}
@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  constructor(private appConfig: AppConfigurationService) {}
  isFeatureFlagEnabled(flag: FeatureFlag) {
    return this.appConfig.featureFlags[flag] === true;
  }

  get demoPreviews() {
    return this.appConfig.featureFlags.demoPreviews === true;
  }
  get devTools() {
    return this.appConfig.featureFlags.devTools === true;
  }
  get devExceptions() {
    return this.appConfig.featureFlags.devExceptions === true;
  }
}
