<div class="s_bg-logout" style="height: 100vh; width: 100vw;">
    <h4 class="s_logout pt-5">
        Safari User ID Not Created
    </h4>
    <br/>
    <div class="text-center s_txt-lg">
        The user ID you provided ({{ email }}) has not been created in Safari. Please contact your company's Safari Coordinator to create it.<br/>
        For more information, see creating users in Safari.<br/>
        <br/>
        <br/>
        <sl-ui-kit-button type="button" class="btn btn-md btn-primary" (clicked)="retryLogin()">Return to login page</sl-ui-kit-button>
    </div>
</div>