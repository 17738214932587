<div *ngIf="!html" class="wrapper common-error-page">
    <!-- MAIN -->
    <main class="s_bg-403">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                    <span class="display-1 d-block">403</span>
                    <div class="mb-4 lead">You are not authorized to access this page.</div>
                   
                    <a [routerLink]="['/']" class="btn btn-link">Back to Home</a>
                    <button type="button" (click)="logout()" class="btn btn-link">Sign Out</button>
                </div>
            </div>
        </div><!-- END: container ______________________________________________________________________________ -->
    </main>
</div><!-- END: wrapper -->
<iframe (load)="onFrameLoaded($event)" #htmlFrame *ngIf="html" src="data:text/html;charset=utf-8" [srcdoc]="html" style="border: 0; width: 100%;"></iframe>