import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthFailedReason, AuthService } from '@safarilaw-webapp/shared/auth';

@Component({
  selector: 'sl-root-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.css']
})
export class LoginErrorComponent implements OnInit {
  authFailedReason: AuthFailedReason;

  constructor(private _activatedRoute: ActivatedRoute, private _authService: AuthService) {}

  ngOnInit() {
    this.authFailedReason = +this._activatedRoute.snapshot.queryParamMap.get('authFailedReason') || AuthFailedReason.Unknown;
    /* Forget the selected company. This prevents users from getting stuck logging into a company where they were deactivated. */
    this._authService.currentCompanyId = null;
  }

  get title(): string {
    if (this.isUserAuth0Deactivated || this.isErrorNoAccessToAnyCompany) {
      return 'Your account has been deactivated.';
    } else if (this.isErrorNoAccessToRequestedCompany) {
      return 'Your account is not active in the requested company.';
    }
    return 'Your authentication was not successful.';
  }

  get message(): string {
    if (this.isUserAuth0Deactivated || this.isErrorNoAccessToAnyCompany || this.isErrorNoAccessToRequestedCompany) {
      return 'Please contact your Coordinator for assistance.';
    }
    return 'Please try again or contact your Coordinator for assistance.';
  }

  get isUserAuth0Deactivated(): boolean {
    return this.authFailedReason === AuthFailedReason.Deactivated;
  }

  get isErrorNoAccessToRequestedCompany(): boolean {
    return this.authFailedReason === AuthFailedReason.NoAccessToRequestedCompany;
  }

  get isErrorNoAccessToAnyCompany(): boolean {
    return this.authFailedReason === AuthFailedReason.NoAccessToAnyCompany;
  }
}
