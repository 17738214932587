import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NoMultiClickDirective } from '../../directives/nomulticlick/nomulticlick-directive';
@Component({
  selector: 'sl-ui-kit-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [NoMultiClickDirective]
})
export class SafariButtonComponent {
  @Input()
  throttleTime = 500;

  @Input()
  disableOnThrottle = false;

  @Input() id: string;
  @Input() type = 'button';
  @Input() class = 'btn s_save btn-success';
  @Input() disabled = false;
  @Output()
  // https://github.com/angular/angular/issues/14619
  clicked: EventEmitter<any> = new EventEmitter();

  emitClick() {
    this.clicked.emit();
  }
}
