import { Component, OnInit } from '@angular/core';
import { PageNotFoundComponent as CommonPageNotFoundComponent } from '@safarilaw-webapp/shared/common-pages';

@Component({
  selector: 'sl-rms-page-not-found',
  templateUrl: '../../../../../../../libs/shared/common-pages/src/lib/error/components/page-not-found/page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends CommonPageNotFoundComponent implements OnInit {
  ngOnInit() {
    if (this._swUpdate.isEnabled) {
      // It's possible that a user got this error page because they opened the app before some API update,
      // and the update introduced some breaking changes. If the user entered that codepath before the next
      // SW poll time they could easily get the error. So let's poll right there and then
      this._swUpdate.checkForUpdate().catch(err => {
        this._loggerService.LogError(err, window.location.href);
      });
    }
  }
}
