export const HTTP_STATUS_CODE_TEMPLATE_VALIDATION = 99998;
export const HTTP_STATUS_CODE_WEB_TUS_CLIENT_ERROR = 99997;
export const HTTP_STATUS_CODE_WEB_VALIDATION_BASE = 90000;

export const HTTP_STATUS_CODE_API_VALIDATION = 400;
export const HTTP_STATUS_CODE_API_CONFLICT = 409;
export const HTTP_STATUS_CODE_API_NOTFOUND = 404;
export const HTTP_STATUS_CODE_API_NOTAUTHORIZED = 401;
export const HTTP_STATUS_CODE_API_FORBIDDEN = 403;
export const HTTP_STATUS_CODE_API_TOOMANY = 429;
export const HTTP_STATUS_CODE_API_NOTMODIFIED = 304;
export const HTTP_STATUS_CODE_API_SERVERERROR = 500;

export const HTTP_STATUS_CODE_GATEWAY_TIMEOUT = 504;
export const HTTP_STATUS_CODE_UNKNOWN_ERROR = 0;
