import { Component } from '@angular/core';

@Component({
  selector: 'sl-ui-kit-form-loading-spinner',
  templateUrl: './form-loading-spinner.component.html',
  styleUrls: ['./form-loading-spinner.component.scss']
})
export class FormLoadingSpinnerComponent {
  constructor() {}
}
