import { HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError, timer } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HTTP_STATUS_CODE_API_NOTAUTHORIZED, HTTP_STATUS_CODE_API_NOTMODIFIED, HTTP_STATUS_CODE_API_SERVERERROR, HTTP_STATUS_CODE_API_TOOMANY, HTTP_STATUS_CODE_API_VALIDATION } from './constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorRetryBuilder {
  getRetryWhenLogic(count) {
    // rxjs is deprecating retryWhen so we have to use retry. The syntax is a bit different though
    return retry({
      // We don't have to manage checking for the retry count anymore
      count,
      // But the "when" part is a bit weirder. This isn't pipeable like the old retryWhen was
      // so we can't pipe to check the error, but according to the docs if the  delay function emits an error
      // retry will stop. If it returns anything else if it will continue up to number of retries specified
      // in the count variable. I also confirmed this in tests by manually hacking different errors and it
      // seems to work same as before.
      delay: (error: any, retryCount) => {
        // Retrofit the non-recoverable check from retryWhen. If we find that it's not recoverable return
        // throwError observable which immediately emits the error and stops furhter retries
        if (this.isNonRecoverableError(error)) {
          return throwError(() => error);
        }
        // Use exponential backoff by increasing the delay with every retry
        return timer(retryCount * 250);
      }
    });
  }
  getCatchErrorLogic(headers, funcToCall, ...funcParams) {
    return catchError((error, caught) => {
      if (this.isAuthError(error)) {
        /* Ensure Headers are populated - They are lazy loaded and won't be serialized by Redux otherwise. */
        error.headers.keys();
        /* Determine if it's basic auth or OAuth */
        if (this.isBasicAuth(headers)) {
          return throwError(() => error);
        } else {
          /* Requesters get a new observable that requests a new token and then retries the original request */
          const params = [...funcParams, error, caught];
          return funcToCall(...params);
        }
      } else {
        return throwError(() => error);
      }
    });
  }
  isBasicAuth(headers: HttpHeaders): boolean {
    return headers.get('Authorization') != null && headers.get('Authorization').startsWith('Basic');
  }
  isAuthError(httpResponse: HttpResponseBase): boolean {
    return httpResponse.status === HTTP_STATUS_CODE_API_NOTAUTHORIZED;
  }
  isNonRecoverableError(httpResponse: HttpResponseBase): boolean {
    // There is no point in hammering the API if we got 401. It won't resolve without token refresh
    // So we need to immediately throw without wasting time with repeated calls. This will get caught
    // one layer above and refresh the token
    if (this.isAuthError(httpResponse)) {
      return true;
    }
    // Angular treats 304 as error. As a matter of fact anything that's not in 200s is probably treated as an error, so
    // 304 falls in that range.
    // In any case - we don't want it to keep retrying on this
    // one. Retrying may or may not be what business logic for a particular page requires so let the crud
    // service and other higher level objects deal with this
    if (httpResponse.status === HTTP_STATUS_CODE_API_NOTMODIFIED) {
      return true;
    }
    // Exclude 429 - sometimes it can be caused by multiple calls to the same endpoint,
    // like during file upload.
    return httpResponse.status !== HTTP_STATUS_CODE_API_TOOMANY && httpResponse.status >= HTTP_STATUS_CODE_API_VALIDATION && httpResponse.status < HTTP_STATUS_CODE_API_SERVERERROR;
  }
}
