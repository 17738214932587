import { Injectable } from '@angular/core';
import { ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { CreateUserModel, PhoneType, UpdateUserProfileModel, UserListModel, UserModel } from '../../_comanage-api-models.generated';
import { SecurityRole } from '../../enums';
import { User, getPhoneNumbersForApi, getPhoneNumbersForApp } from '../models/app/user';

@Injectable({
  providedIn: 'root'
})
export class UserAdapter extends ApiDataAdapter<User> {
  toCreateModel(user: User): Required<CreateUserModel> {
    const phoneNumbers = [];
    if (user.workPhone) {
      phoneNumbers.push({
        number: user.workPhone,
        type: PhoneType.WorkPhone,
        isPrimary: true,
        extension: user.workPhoneExtension
      });
    }
    if (user.mobilePhone) {
      phoneNumbers.push({
        number: user.mobilePhone,
        type: PhoneType.CellPhone,
        isPrimary: false,
        extension: null
      });
    }

    return {
      email: user.email,
      firstName: user.firstName,
      isActive: user.isActive,
      jobTitle: user.jobTitle,
      lastName: user.lastName,
      teamId: user.teamId as unknown as number,

      phoneNumbers,
      timeZone: user.timeZone,
      roles: user.roles != null ? user.roles.map(o => o as unknown as number) : null,
      securityRoles: null,
      matterTypes: user.matterTypes,
      matterSubtypeIds: user.matterSubtypeIds != null ? user.matterSubtypeIds.map(o => o as unknown as number) : null,
      hasAccessToAllMatterSubtypes: user.hasAccessToAllMatterSubtypes,
      /* This only exists on the create model */
      disableWelcomeEmail: !user.sendNewUserEmail
    };
  }
  toUpdateModel(user: User): Required<UpdateUserProfileModel> {
    return {
      email: user.email,
      firstName: user.firstName,
      isActive: user.isActive,
      jobTitle: user.jobTitle,
      lastName: user.lastName,

      teamId: user.teamId as unknown as number,
      ...getPhoneNumbersForApi(user),
      timeZone: user.timeZone,
      roles: user.roles != null ? user.roles.map(o => o as unknown as number) : null,
      securityRoles: null,
      matterTypes: user.matterTypes,
      matterSubtypeIds: user.matterSubtypeIds != null ? user.matterSubtypeIds.map(o => o as unknown as number) : null,
      hasAccessToAllMatterSubtypes: user.hasAccessToAllMatterSubtypes
    };
  }
  fromGetModel(user: UserModel): Required<User> {
    return {
      email: user.email,
      firstName: user.firstName,
      id: user.id.toString(),
      isActive: user.isActive,
      jobTitle: user.jobTitle,
      lastName: user.lastName,
      teamId: user.teamId as unknown as string,

      ...getPhoneNumbersForApp(user),
      timeZone: user.timeZone,
      roles: user.roles,
      isCoordinator: user.isCoordinator,
      hasAccessToAllMatterSubtypes: user.hasAccessToAllMatterSubtypes,
      matterTypes: user.matterTypes,
      matterSubtypeIds: user.matterSubtypeIds.map(o => o.toString()),
      sendNewUserEmail: false,
      hasSignature: user.hasSignature,
      authUserId: user.authUserId?.toString(),
      initials: user.firstName.charAt(0) + user.lastName.charAt(0),

      signature: null,
      securityRoles: [...user.securityRoles],
      lastLoginDate: user.lastLoginDate,
      isAdmin: user.securityRoles.includes(SecurityRole.Admin),
      isOwner: user.securityRoles.includes(SecurityRole.Owner)
    };
  }

  fromListModel(user: UserListModel): Required<User> {
    return {
      email: user.email,
      firstName: user.firstName,
      id: user.id as unknown as string,
      isActive: user.isActive,
      jobTitle: user.jobTitle,
      lastName: user.lastName,
      teamId: user.teamId as unknown as string,

      ...getPhoneNumbersForApp(user),
      timeZone: undefined,
      roles: user.roles,
      hasAccessToAllMatterSubtypes: user.hasAccessToAllMatterSubtypes,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- why
      matterTypes: user.matterTypes,
      matterSubtypeIds: user.matterSubtypeIds.map(o => o as unknown as string),
      hasSignature: user.hasSignature,

      sendNewUserEmail: undefined,
      authUserId: undefined,
      initials: user.firstName.charAt(0) + user.lastName.charAt(0),

      isCoordinator: undefined,
      signature: undefined,
      securityRoles: [...user.securityRoles],
      lastLoginDate: user.lastLoginDate,
      isAdmin: user.securityRoles.includes(SecurityRole.Admin),
      isOwner: user.securityRoles.includes(SecurityRole.Owner)
    };
  }
  fromGetNewModel(): Required<User> {
    return {
      id: ObjectHelper.getNewId(),
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',

      teamId: undefined,

      workPhone: '',
      workPhoneExtension: '',
      mobilePhone: '',
      isActive: true,
      isCoordinator: false,
      timeZone: 'Pacific Standard Time',
      roles: [],
      hasAccessToAllMatterSubtypes: true,
      matterTypes: [],
      matterSubtypeIds: [],
      sendNewUserEmail: true,
      hasSignature: false,
      lastLoginDate: null,
      signature: null,
      authUserId: null,
      initials: null,
      securityRoles: undefined,
      isAdmin: false,
      isOwner: false
    };
  }
}
