<div class="wrapper common-error-page">
    <!-- MAIN -->
    <main class="s_bg-404">
        <div class="container-fluid">
            <div class="justify-content-center">
                <div class="col-md-12 text-center">
                    <span class="display-1 d-block">404</span>
                    <div class="mb-4 lead">The page you are looking for was not found.</div>
                    <a [routerLink]="['/']" class="btn btn-link">Back to Home</a>
                </div>
            </div>
        </div>        
    </main>
</div><!-- END: wrapper -->