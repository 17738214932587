import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@safarilaw-webapp/shared/auth';
import { AuthReduxObject } from '@safarilaw-webapp/shared/common-objects-models';

@Component({
  selector: 'sl-root-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(protected _authService: AuthService, protected _route: ActivatedRoute, protected _router: Router, protected _store: Store<any>, protected _authRO: AuthReduxObject) {}

  showBackground = false;
  connection = null;

  doLogin() {
    const redirectTargetRoute = history.state.redirectTargetRoute;
    if (this._authService.isAuthenticated()) {
      void this._router.navigateByUrl(redirectTargetRoute);
    } else {
      this._store.dispatch(
        this._authRO.default.actions.login({
          payload: {
            redirectTargetRoute: redirectTargetRoute || null,
            prefilledEmail: null,
            connection: this.connection,
            startTimer: true
          }
        })
      );
    }
  }

  ngOnInit() {
    if (document.referrer?.endsWith('login-error')) {
      this.showBackground = true;
    }

    this.connection = this._route.snapshot.queryParamMap.get('connection');

    this.doLogin();
  }
}
