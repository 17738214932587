import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { UserVerificationService } from '@safarilaw-webapp/shared/auth';
import { LoggerService } from '@safarilaw-webapp/shared/logging';

@Component({
  selector: 'sl-root-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  constructor(protected _userVerificationService: UserVerificationService, protected _router: Router, protected _swUpdate: SwUpdate, protected _loggerService: LoggerService) {}

  ngOnInit() {
    // if we got to this page because we have an auth0 user but no safari user
    // then try to re-run sso init
    this._userVerificationService.checkForUser().subscribe(isUserFound => {
      if (!isUserFound) {
        // 404 implies we need to do the SSO hookup OR the user might need to choose a company
        this._userVerificationService.checkForCompanies().subscribe(hasCompanies => {
          if (hasCompanies) {
            void this._router.navigate(['/select-company']);
          } else {
            void this._router.navigate(['/sso-init']);
          }
        });
      } else {
        if (this._swUpdate.isEnabled) {
          // It's possible that a user got this error page because they opened the app before some API update,
          // and the update introduced some breaking changes. If the user entered that codepath before the next
          // SW poll time they could easily get the error. So let's poll right there and then
          this._swUpdate.checkForUpdate().catch(err => {
            this._loggerService.LogError(err, window.location.href);
          });
        }
      }
    });
  }
}
