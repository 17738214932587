import { Component, OnInit } from '@angular/core';
import { MyIntakePortal, RmsDataAccessObject } from '@safarilaw-webapp/feature/rms/data-access';
import { NavbarComponent } from '@safarilaw-webapp/shared/app-bootstrap';
import { Observable, map, mergeMap, of } from 'rxjs';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector -- ignore NG
  selector: 'sl-bootstrap-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class RmsNavbarComponent extends NavbarComponent implements OnInit {
  RmsObject: RmsDataAccessObject;
  recentIntakePortals$: Observable<MyIntakePortal[]>;

  constructor() {
    super();

    this.RmsObject = this.inject(RmsDataAccessObject);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.recentIntakePortals$ = this._authService.isAuthenticated$.pipe(
      mergeMap(isAuthenticated => {
        if (isAuthenticated) {
          return this.loadObjectList$(this.RmsObject.MyIntakePortal);
        } else {
          return of({ items: [] as MyIntakePortal[] });
        }
      }),
      map(o => o.items)
    );
  }
}
