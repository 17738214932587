<ng-container *ngIf="errorObject && !isChunkError">
  <ng-container *ngIf="showDeveloperExceptions(); else prodTemplate">
    
    <h1>An unexpected error has occurred <b>...</b></h1>
   
    <br>
    <h5><p>Please <button type="button"  class="link-button " id="refresh-link" (click)="refresh()">CLICK HERE</button> to try to resolve the error automatically.</p></h5>
    <div >
      <h5 class="pull-left"><strong><u>{{ getErrorTitle() }}</u></strong></h5>
     
      <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div >
      <p class="lead">{{ getMessageHeaderFromErrorObject() }}</p>
      <div *ngIf="isSafariValidationError()">
        <ul *ngFor="let detail of errorObject.details">
          <li> {{ detail.message }} </li>
        </ul>
      </div>
      <div *ngIf="isConflictError()">
        Another user has changed the data you were editing. The page will now reload with the latest data. 
        Please review the latest changes and re-enter your data.
      </div>
      <div *ngIf="!isKnownError()">
        URL: {{ url }} <br>
        {{ errorObject.message ? errorObject.message : 'Unknown Error' }}
        <br>
        
      </div>
      <div *ngIf="errorObject.developerException && showDeveloperExceptions()">
  
        <a  class="text-muted" role="button"  (click)="isCollapsed = !isCollapsed" >
            Click here for detailed exception (dev mode only)
        </a>
        <div  [collapse]="isCollapsed">
        <h5 class="my-2"><u ><strong></strong></u></h5>
        <div ><strong>Message:</strong> </div>
        <div> {{ errorObject.developerException.message }} </div>
        <div ><strong>Source:</strong> </div>
        <div> {{ errorObject.developerException.source }} </div>
        <div ><strong>Stack Trace: </strong></div>
        <div class="mb-2 my-2 w-100 overflow-auto" >
          {{ errorObject.developerException.stackTrace }}
        </div>
        </div>
      </div>
      <div *ngIf="errorObject.jsStack">
        <h5 class="my-2"><u ><strong>Detailed JS Error</strong></u></h5>
        <div class="mb-2 my-2 w-100 overflow-auto"> {{ errorObject.jsStack }}</div>
      </div>
      <div>
        <hr>
        Timestamp: {{ currentDateTime.toISOString() }}
        <br/>
        Unique ID: {{ errorObject.uuid }}
      </div>
  
  
    </div>
    <br>
    <strong>NOTE: This is a DEV environment error. Click <button type="button" (click)="forceProdMode()"> here </button> to see PROD version</strong>
  </ng-container>
</ng-container>
<ng-template #prodTemplate>
 
     
        <h1>An unexpected error has occurred <b>...</b></h1>
        <br>
        <h5><p>Please <button type="button" class="link-button" id="refresh-link" (click)="refresh()">CLICK HERE</button> to try to resolve the error automatically.</p></h5>
        <hr>
        <p>If the error persists please contact the support at <a  [href]="'mailto:'+supportEmail">{{supportEmail}}</a> with the details about the error and the error unique ID: <br><br> [{{ errorObject.uuid }}]  </p>
        
   

        <hr>
 
</ng-template>
 