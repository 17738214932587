import { SafeResourceUrl } from '@angular/platform-browser';
import { SafariObjectId } from '../base-object/models/app/safari-object-base';

export enum FileOperationType {
  Add,
  Remove,
  Download,
  RemoveMultiple,
  Move
}

// Typescript uses structural typing, meaning that we can't use things
// like empty marker interfaces to catch type errors in compiler. Instead
// the below solution can be used to create nominal types similar to marker interfaces
// TO USE - add the following the the app's file uploader implementation:
// interface IXYZFileOperationMetadataAdditionalInfo {
//   someProp: SomeType;
// }
// export type XYZFileOperationMetadataAdditionalInfo = FileOperationMetadataAdditionalInfo & IXyzFileOperationMetadataAdditionalInfo;
const brand = Symbol();
export interface FileOperationMetadataAdditionalInfo {
  [brand]: never;
}

export class FileOperationMetadata {
  // parent's etag. May or may not be needed, depending on the use case
  etag?: string;
  // anything that makes sense to a specific implementation of file uploader
  additionalInfo?: FileOperationMetadataAdditionalInfo | any;
  // additional form values that will be sent with the file
  formValues?: Map<string, string> = null;
}
// Base class for all file operations
export class FileOperationBase {
  id?: string; // if present means replace
  objectTypeName?: string;
  isSubfolder?: boolean;
  file?: File; // file contents
  folder?: string;
  subfolder?: string;
  correlationId?: string;
  secondsUntilTransferDialogShown?: number; // How many seconds should elapse before we popup the dialog. Usually 0. -1 means never
  actionId?: string; // Must be present - if not assigned by the caller it will be assigned by the framework
  fileName?: string; // "Raw" filename
  displayFilename?: string; // displayFilename - if not assigned by the caller it will be assigned by the service (default = raw filename)
  parentId?: SafariObjectId; // Parent object (company Id, subpoena Id, etc)
  metadata?: FileOperationMetadata; // Whatever makes sense to a particular service
  originalContent?: any;
}
// Base class for upload and remove file operations
export class FileOperationRequest extends FileOperationBase {}
export class BulkFileOperationRequest {
  filesToAdd?: FileOperationUploadRequest[];
  filesToRemove?: FileOperationRemoveRequest[];
  filesToDownload?: FileOperationDownloadRequest[];
}
export class ClearFileInfoPayload {
  id: string;
  fileTransferDialogId: string;
  hadErrors: boolean;
  wasCancelled: boolean;
  correlationId: string;
}
// File upload class
export class FileOperationUploadRequest extends FileOperationBase {}
// File remove class
export class FileOperationRemoveRequest extends FileOperationBase {}
export class FileOperationMoveRequest extends FileOperationBase {}
export class FileOperationMoveMultipleFilesRequest extends FileOperationBase {
  folder: string;
  subfolder?: string;
  fileRequests: FileOperationMoveRequest[]; // ID of the file object
}
export class FileOperationRemoveMultipleFilesRequest extends FileOperationBase {
  useQueryString?: boolean;
  fileRequests: FileOperationRemoveRequest[]; // ID of the file object
}
export class FileOperationDownloadRequest extends FileOperationBase {
  isPreview?: boolean;
  headers?: { [key: string]: string };
}
export class FileOperationGenerateZipRequest extends FileOperationBase {
  attachmentIds: string[];
}
// Info class. Used by the framework to display information about the file
export class FileOperationInfo extends FileOperationBase {
  apiResponse?: any;
  isPreview?: boolean;
  downloadLink?: string; // download link returned from the API
  percentComplete?: number; // progress
  isError?: boolean; // error flag
  message?: string; // message to display in the dialog
  fileOperationType?: FileOperationType; // remove or add
  totalSize?: number;
  totalProcessed?: number;
  isMetadataUpdate?: boolean;
}
export enum FilePreviewRequestFileUrlType {
  SafeResource,
  DirectLink,
  RemoteContent,
  FileContent,
  RequestKeyboardPaste
}
export class FilePreviewRequest {
  viewerId?: SafariObjectId;
  filePreviewSource: SafeResourceUrl | string | Blob;
  fileType?: string;
  fileName: string;
  urlType: FilePreviewRequestFileUrlType;
  canDownload?: boolean;
  canEditFileName?: boolean;
  revokeUrlWhenDone?: boolean; // only matters if urlType is SafeResource
  hideHeader?: boolean;
  additionalInfo?: any;
  linkOriginInfo?: {
    id: SafariObjectId;
    attachmentType: string;
  };
}
export class FilePreviewResponse {
  fileName?: string;
  file?: File; // not used currently
  additionalInfo: any;
  __responseType?: FilePreviewResponseType; // autopopulated by reducer
}
export class RemoveFileInfo extends FileOperationInfo {}

export enum FilePreviewResponseType {
  Closed,
  Edit
}
