import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedAppBootstrapModule } from '@safarilaw-webapp/shared/app-bootstrap';
import { RmsHeaderComponent } from './header/components/header.component';
import { RmsNavbarComponent } from './navbar/components/navbar.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
@NgModule({
  declarations: [RmsHeaderComponent, RmsNavbarComponent],
  exports: [RmsHeaderComponent, RmsNavbarComponent],
  imports: [SharedAppBootstrapModule, RouterModule, CommonModule, BrowserAnimationsModule, CollapseModule.forRoot(), BsDropdownModule.forRoot()]
})
export class FeatureRmsSharedModule {}
