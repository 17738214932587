import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@safarilaw-webapp/shared/auth';

@Component({
  selector: 'sl-root-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(protected _authService: AuthService, protected route: ActivatedRoute) {}
  public loggedOut = false;
  ngOnInit() {
    if (this._authService.isAuthenticated()) {
      // If we're currently authenticated call authService.logout. Upon success this will come back to this same page
      // but at that second call isAuthenticated will be false
      this._authService.logout();
    } else {
      // If coming in here manually OR if this was a loopback from authService.logout set the var to true so we can see the text
      this.loggedOut = true;
    }
  }
}
