import { SecurityRole } from '../../../enums';
import { Phone } from '../../../phone/models/app/phone';

import { MatterType, PhoneType } from '../../../_comanage-api-models.generated';
export interface IApiUserPhoneNumbers {
  phoneNumbers: Partial<Phone>[];
}
export interface IAppUserPhoneNumbers {
  workPhone: string;
  workPhoneExtension: string;
  mobilePhone: string;
  workFax?: string;
  workFaxExtension?: string;
}

export class User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  jobTitle: string;
  timeZone: string;

  teamId?: string | undefined;
  matterTypes: MatterType[];
  matterSubtypeIds: string[];
  authUserId?: string;
  hasSignature: boolean;

  /* Helpful extra properties we compute separately from the API */
  initials?: string;
  signature?: File;
  sendNewUserEmail: boolean;

  isCoordinator: boolean;
  hasAccessToAllMatterSubtypes: boolean;
  // TODO: These both seem to be the same but one is defined as string, the other one enum
  // Need to confirm with Dan/Rich which one is the better one
  roles: string[];
  securityRoles: SecurityRole[];
  /* These have a specialized mapping in the adapter. */
  workPhone: string;
  workPhoneExtension: string;
  mobilePhone: string;
  lastLoginDate: string;

  isAdmin: boolean;
  isOwner: boolean;
}

export const getPhoneNumbersForApp: (user: Partial<IApiUserPhoneNumbers>) => IAppUserPhoneNumbers = (user: Partial<IApiUserPhoneNumbers>): IAppUserPhoneNumbers => {
  const mobilePhoneModel = user.phoneNumbers?.find(o => o.type === PhoneType.CellPhone);
  const workPhoneModel = user.phoneNumbers?.find(o => o.type === PhoneType.WorkPhone);
  const workFaxModel = user.phoneNumbers?.find(o => o.type === PhoneType.WorkFax);
  return {
    workPhone: workPhoneModel?.number,
    workPhoneExtension: workPhoneModel?.extension,
    mobilePhone: mobilePhoneModel?.number,
    workFax: workFaxModel?.number,
    workFaxExtension: workFaxModel?.extension
  };
};

export const getPhoneNumbersForApi: (user: IAppUserPhoneNumbers) => IApiUserPhoneNumbers = (user: IAppUserPhoneNumbers): IApiUserPhoneNumbers => {
  const phoneNumbers = [];
  if (user.workPhone) {
    phoneNumbers.push({
      number: user.workPhone,
      type: PhoneType.WorkPhone,
      isPrimary: true,
      extension: user.workPhoneExtension
    });
  }
  if (user.mobilePhone) {
    phoneNumbers.push({
      number: user.mobilePhone,
      type: PhoneType.CellPhone,
      isPrimary: false,
      extension: null
    });
  }
  if (user.workFax) {
    phoneNumbers.push({
      number: user.workFax,
      type: PhoneType.WorkFax,
      isPrimary: false,
      extension: user.workFaxExtension
    });
  }
  return { phoneNumbers };
};
