import { Component } from '@angular/core';

@Component({
  selector: 'sl-root-interstitial',
  templateUrl: './interstitial.component.html',
  styleUrls: ['./interstitial.component.css']
})
export class InterstitialComponent {
  constructor() {}
}
