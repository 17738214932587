import { ActionCreator, createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { IdName, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { ActionErrorBase } from '../../models/object';

import { DropdownActionTypes } from './enums';

export class DropdownIdActionInfo {
  id: SafariObjectId;
  abort?: SafariObjectId;
}
export class DropdownIdActionInfoBulk {
  id: SafariObjectId;
  dropdownIds: SafariObjectId[];
  abort?: SafariObjectId[];
}
export class DropdownLoadSuccessActionInfo extends DropdownIdActionInfo {
  dropdownValues: IdName[];
}

const createDropdownClearAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DropdownIdActionInfo>());
};
const createLoadDropdownAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DropdownIdActionInfo>());
};
const createLoadBulkDropdownAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DropdownIdActionInfoBulk>());
};
const createLoadDropdownSuccessAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DropdownLoadSuccessActionInfo>());
};
const createLoadDropdownFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<ActionErrorBase>());
};
export const populateDefaultDropdownActions = (actionMap: Map<number, string>) =>
  new DefaultDropdownActionConverter({
    clearDropdown: createDropdownClearAction(actionMap.get(DropdownActionTypes.ClearDropdown)),
    loadDropdown: createLoadDropdownAction(actionMap.get(DropdownActionTypes.LoadDropdown)),
    loadDropdownSuccess: createLoadDropdownSuccessAction(actionMap.get(DropdownActionTypes.LoadDropdownSuccess)),
    loadDropdownFail: createLoadDropdownFailAction(actionMap.get(DropdownActionTypes.LoadDropdownFail)),
    loadBulkDropdown: createLoadBulkDropdownAction(actionMap.get(DropdownActionTypes.LoadBulkDropdown)),
    loadBulkDropdownSuccess: createLoadDropdownSuccessAction(actionMap.get(DropdownActionTypes.LoadBulkDropdownSuccess)),
    loadBulkDropdownFail: createLoadDropdownFailAction(actionMap.get(DropdownActionTypes.LoadBulkDropdownFail))
  });

export class DefaultDropdownActionConverter {
  constructor(
    private _actions: {
      clearDropdown?: ActionCreator<any, (props: DropdownIdActionInfo) => DropdownIdActionInfo & TypedAction<string>>;
      loadDropdown?: ActionCreator<any, (props: DropdownIdActionInfo) => DropdownIdActionInfo & TypedAction<string>>;
      loadDropdownSuccess?: ActionCreator<any, (props: DropdownLoadSuccessActionInfo) => DropdownLoadSuccessActionInfo & TypedAction<string>>;
      loadDropdownFail?: ActionCreator<any, (props: ActionErrorBase<any>) => ActionErrorBase<any> & TypedAction<string>>;
      loadBulkDropdown?: ActionCreator<any, (props: DropdownIdActionInfoBulk) => DropdownIdActionInfoBulk & TypedAction<string>>;
      loadBulkDropdownSuccess?: ActionCreator<any, (props: DropdownLoadSuccessActionInfo) => DropdownLoadSuccessActionInfo & TypedAction<string>>;
      loadBulkDropdownFail?: ActionCreator<any, (props: ActionErrorBase<any>) => ActionErrorBase<any> & TypedAction<string>>;
    }
  ) {}
  get clearDropdown() {
    return this._actions.clearDropdown;
  }
  get loadDropdown() {
    return this._actions.loadDropdown;
  }
  get loadBulkDropdown() {
    return this._actions.loadBulkDropdown;
  }
  get loadDropdownSuccess() {
    return this._actions.loadDropdownSuccess;
  }
  get loadDropdownFail() {
    return this._actions.loadDropdownFail;
  }
  get loadBulkDropdownSuccess() {
    return this._actions.loadBulkDropdownSuccess;
  }
  get loadBulkDropdownFail() {
    return this._actions.loadBulkDropdownFail;
  }
}
