import { Component } from '@angular/core';
import { UnauthorizedComponent as CommonUnauthorizedComponent } from '@safarilaw-webapp/shared/common-pages';

@Component({
  selector: 'sl-rms-unauthorized',
  templateUrl: '../../../../../../../libs/shared/common-pages/src/lib/error/components/unauthorized/unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent extends CommonUnauthorizedComponent {
  override checkForUser() {
    // Do nothing here, does not apply
  }
}
