import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sl-root-clear-storage',
  templateUrl: './clear-storage.component.html',
  styleUrls: ['./clear-storage.component.css']
})
export class ClearStorageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    localStorage.clear();
  }
}
