// import { createFeatureSelector } from '@ngrx/store';
// import { IdName } from '@safarilaw-webapp/shared/common-objects-models';
// import { IDropdownState, IFileUploadState, ISafariObjectState } from '@safarilaw-webapp/shared/redux';
// import { User } from '../co-user/models/app/user';
// import { CompanySettings } from '../company-settings/models/app/company-settings';
// import { Company } from '../company/models/app/company';
// import { InboundEmailSetting } from '../inbound-email-setting/models/app/inbound-email-setting';
// import { NotificationPreference } from '../notification-preferences/models/app/notification-preference';
// import { RegisteredAgentSettings } from '../registered-agent-settings/models/app/registered-agent-settings';
// import { Team } from '../team/models/app/team';

import { createFeatureSelector } from '@ngrx/store';
import { IdName } from '@safarilaw-webapp/shared/common-objects-models';
import { IDropdownState, IFileUploadState, ISafariObjectState } from '@safarilaw-webapp/shared/redux';
import { AttachmentLink } from '../../../../../../shared/common-objects-models/src/lib/attachment-link/models/app/attachment-link';
import { DeliveryPortal } from '../delivery-portal/models/app/delivery-portal';
import { IntakePortalForm } from '../intake-portal-form/models/app/intake-portal-form';
import { IntakePortal } from '../intake-portal/models/app/intake-portal';
import { MyIntakePortal } from '../my-intake-portal/models/app/my-intake-portal';
import { OrganizationSearch } from '../ogranization-search/models/app/organization-search';
import { Profile } from '../profile/models/app/profile';
import { Submission } from '../submission/models/app/submission';

export interface IRmsStateDropdown extends IDropdownState {
  countries: IdName[];
  states: IdName[];
  timezones: IdName[];
  userpermissions: IdName[];
  applications: IdName[];
  informationRequestTypes: IdName[];
}

export interface IRmsState {
  myCombinedState: {
    profileState: ISafariObjectState<Profile>;
    intakePortalState: ISafariObjectState<MyIntakePortal>;
  };
  deliveryPortalCombinedState: {
    deliveryPortalState: ISafariObjectState<DeliveryPortal>;
  };
  organizationCombinedState: {
    searchState: ISafariObjectState<OrganizationSearch>;
  };
  intakePortalCombinedState: {
    intakePortalState: ISafariObjectState<IntakePortal>;
    intakePortalUrlState: ISafariObjectState<IntakePortal>;
  };
  intakeFormCombinedState: {
    intakeFormState: ISafariObjectState<IntakePortalForm>;
  };
  submissionCombinedState: {
    submissionState: ISafariObjectState<Submission>;
  };
  attachmentLinkCombinedState: {
    attachmentLinkState: ISafariObjectState<AttachmentLink>;
  };

  dropdownCombinedState: {
    dropdownState: IRmsStateDropdown;
  };
  fileCombinedState: {
    fileState: IFileUploadState;
  };
}

export const FEATURE_STATE = 'data-access-rms';
export const getSharedFeatureState = createFeatureSelector<IRmsState>(FEATURE_STATE);

export const PROJECT_NAME = 'Rms';
