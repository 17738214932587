import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppUiReduxObject } from '@safarilaw-webapp/shared/app-bootstrap';

import { AuthClaim, AuthService, CoUserSsoService } from '@safarilaw-webapp/shared/auth';
import { HTTP_STATUS_CODE_API_NOTFOUND, HTTP_STATUS_CODE_API_VALIDATION } from '@safarilaw-webapp/shared/common-objects-models';
import { catchError, map, of, take, throwError } from 'rxjs';

@Component({
  selector: 'sl-root-login-no-user',
  templateUrl: './login-no-user.component.html',
  styleUrls: ['./login-no-user.component.css']
})
export class LoginNoUserComponent implements OnInit {
  constructor(private _store: Store<any>, private _router: Router, private _authService: AuthService, private _ssoService: CoUserSsoService, private _appUiReduxObject: AppUiReduxObject) {}

  private _email: string;
  get email(): string {
    return this._email;
  }

  ngOnInit(): void {
    if (!this._authService.getAccessToken()) {
      void this._router.navigate(['/']);
      return;
    }
    // Let's do one more getSsoUser check
    // This is similar to the one we do in co-sso-user-init component, but the difference is in
    // how the error is processed. If we run into an error that means we still dont' have SSO user
    // and most likely this came from redirect from sso-user. On the other hand if no error is thrown
    // then either sso finished in between transition OR this was the case of someone manually coming in here.
    // In that case just redirect back to default
    this._ssoService
      .getSsoUser()
      .pipe(
        take(1),
        // We don't care about user returned, just that it exists, so we'll map to true (navigate to the root page)

        map(() => true),
        catchError(error => {
          if (error.status === HTTP_STATUS_CODE_API_VALIDATION && error.error.error.message === "The current user's authorization is already setup") {
            // Retrieval failed but it has "user already setup" error, so we'll just return true to force navigation
            // in subscribe
            return of(true);
          } else if (error.status === HTTP_STATUS_CODE_API_NOTFOUND) {
            // The user is still not set up
            return of(false);
          } else {
            return throwError(() => error);
          }
        })
      )
      .subscribe(userAlreadySetUp => {
        if (userAlreadySetUp) {
          void this._router.navigate(['/']);
        } else {
          this._email = this._authService.getIdClaim(AuthClaim.Email);
          if (this._email == null) {
            this._email = 'unknown';
          }
        }
      });
  }

  retryLogin(): void {
    this._store.dispatch(this._appUiReduxObject.default.actions.toggleBlockUi({ payload: { value: true } }));

    void this._router.navigate(['/sso-init']);
  }
}
